import { useLocalStorage } from 'react-use';

// Constants
const DEFAULT_MAX_QUEUE_SIZE = 100;
const LOCAL_STORAGE_KEYS = {
  PRODUCTS: 'recently-browsed-products-queue',
  BRANDS: 'recently-browsed-brands-queue',
  // Add more keys as needed
};

function useLocalStorageQueue(localStorageKey, maxQueueSize = DEFAULT_MAX_QUEUE_SIZE) {
  const [queue, setQueue] = useLocalStorage(localStorageKey, []);

  const addToQueue = (newObject) => {
    if (!newObject || !newObject.id) {
      console.error('New object must have an id.'); // HERE[1]
      return;
    }

    const newQueue = JSON.parse(JSON.stringify(queue));

    const newObjectWithTimestamp = { ...newObject, timestamp: new Date().toISOString() };

    const existingIndex = newQueue.findIndex((item) => item.id === newObject.id);

    if (existingIndex !== -1) {
      newQueue.splice(existingIndex, 1); // Remove the existing object
    }

    newQueue.unshift(newObjectWithTimestamp);

    if (newQueue.length > maxQueueSize) {
      newQueue.pop(); // Remove the last element if the queue exceeds max size
    }

    setQueue(newQueue);
  };

  return { queue, addToQueue };
}

export { useLocalStorageQueue, LOCAL_STORAGE_KEYS };
