import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

/** Components */
import BrandInformation from 'components/Brand/BrandInformation';
import StorePageSeo from 'components/Seo/StorePageSeo';
import AlgoliaResultsLayout from 'components/common/AlgoliaResultsLayout/AlgoliaResultsLayout';

/* Layout */
import { FullWidthLayout } from 'layouts';

/* mui */
import Box from '@mui/material/Box';

const brandPageLayoutDefaultPropBrandData = {
  id: '',
  attributes: {
    wholesalerName: '',
    wholesalerDescription: '',
  },
  store: {
    wholesalerStoreRating: '',
    wholesalerStoreNumberOfRatings: '',
    wholesalerStoreImages: {
      wholesalerStoreBanner: {
        wholesalerStoreBannerImage: '',
        wholesalerStoreBannerBasePath: '',
      },
    },
  },
};

const brandPageLayoutDefaultPropInitialProductsData = {
  products: [],
  totalProducts: 0,
};

const brandPageLayoutDefaultPropInitialAlgoliaData = {
  filters: [],
  banners: [],
  products: [],
  totalProducts: 0,
  brands: [],
  totalBrands: 0,
};

const BrandPageLayout = ({
  slug,
  brandData = brandPageLayoutDefaultPropBrandData,
  initialProductsData = brandPageLayoutDefaultPropInitialProductsData,
  initialAlgoliaData = brandPageLayoutDefaultPropInitialAlgoliaData,
}) => {
  const router = useRouter();
  const { asPath, pathname } = router;

  return (
    <>
      <StorePageSeo
        title={brandData?.attributes?.wholesalerName}
        url={`https://www.creoate.com${asPath}`}
        bannerImage={`https://${brandData?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerBasePath}/${brandData?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerImage}`}
        canonical={`https://www.creoate.com${pathname}`}
        description={brandData?.attributes?.wholesalerDescription}
        products={initialProductsData?.products?.slice(0, 5)}
        address={brandData?.store?.wholesalerStoreCountry}
      />

      <Box sx={{ width: '100%', pb: 1.25 }}>
        <Box>
          <BrandInformation brand={brandData} />
        </Box>

        <AlgoliaResultsLayout
          reference="store"
          searchQuery={slug}
          initialAlgoliaData={initialAlgoliaData}
          key={`${slug}-results-layout`}
        />
      </Box>
    </>
  );
};

BrandPageLayout.propTypes = {
  slug: PropTypes.string.isRequired,
  brandData: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerDescription: PropTypes.string,
    }),
    store: PropTypes.shape({
      wholesalerStoreRating: PropTypes.string,
      wholesalerShipsInDays: PropTypes.string,
      wholesalerStoreCountry: PropTypes.string,
      wholesalerStoreNumberOfRatings: PropTypes.string,
      wholesalerStoreImages: PropTypes.shape({
        wholesalerStoreBanner: PropTypes.shape({
          wholesalerStoreBannerImage: PropTypes.string,
          wholesalerStoreBannerBasePath: PropTypes.string,
        }),
      }),
    }),
  }),
  initialProductsData: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
  }),
  initialAlgoliaData: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    banners: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
    brands: PropTypes.arrayOf(PropTypes.shape({})),
    totalBrands: PropTypes.number,
  }),
};

BrandPageLayout.getLayout = FullWidthLayout;

export default BrandPageLayout;
