import PropTypes from 'prop-types';

import { useUpdateEffect } from 'react-use';
import React, { useState, useCallback, useEffect } from 'react';

/** Hooks */
import { useTheme } from '@mui/material/styles';

/** utils */
import bffClient from 'services/api/bff';

/** Components */
import Grid from '@mui/material/Grid';
import CBreadcrumbs from 'components/CBreadcrumbs';
import BrandCategoriesList from 'components/Brand/BrandCategoriesList';
import StoreCategoryChildrenList from './StoreCategoryChildrenList';

const navigationByCategoriesDefaultPropSx = {};
const navigationByCategoriesDefaultPropBrandCategory = [{}];
const navigationByCategoriesDefaultPropStorePageBreadcrumbs = [{}];

const NavigationByCategories = ({
  brandSlug,
  categorySlug = '',
  onClickHandler,
  sx = navigationByCategoriesDefaultPropSx,
  shippingCountryCode = '',
  brandCategory = navigationByCategoriesDefaultPropBrandCategory,
  storePageBreadcrumbs = navigationByCategoriesDefaultPropStorePageBreadcrumbs,
  selectedCategoryChip = '',
  setSelectedCategoryChip,
}) => {
  const theme = useTheme();
  const [subCategories, setSubCategories] = useState(null);
  const [isFetchingSubCategories, setIsFetchingSubCategories] = useState(false);

  useEffect(() => {
    if (brandSlug === selectedCategoryChip) {
      setSubCategories(null);
    }
    setSelectedCategoryChip(categorySlug || '');
  }, [categorySlug, brandSlug, storePageBreadcrumbs]);

  const onChipClickHandler = (name, slug) => {
    const isBreadcrumbCategory = storePageBreadcrumbs.some((breadcrumb) => breadcrumb.slug === slug);
    if (isBreadcrumbCategory) {
      setSelectedCategoryChip(slug);
    }
    if (!slug) {
      setSubCategories(null);
      setSelectedCategoryChip('');
    } else {
      setSelectedCategoryChip(slug);
    }
    onClickHandler(name, slug);
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    onChipClickHandler(breadcrumb.name, breadcrumb.slug);
  };

  const fetchSubCategories = useCallback(async () => {
    if (selectedCategoryChip) {
      try {
        setIsFetchingSubCategories(true);
        const { data } = await bffClient.get(
          `/categories/${selectedCategoryChip}?brand=${brandSlug}&shippingCountryCode=${shippingCountryCode}`
        );
        setSubCategories(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetchingSubCategories(false);
      }
    }
  }, [selectedCategoryChip, shippingCountryCode, brandSlug]);

  useUpdateEffect(() => {
    (async () => {
      await fetchSubCategories();
    })();
  }, [selectedCategoryChip, storePageBreadcrumbs]);

  // reset
  useUpdateEffect(() => {
    setSubCategories(null);
    setSelectedCategoryChip('');
  }, [brandSlug]);

  return (
    <Grid container sx={{ ...sx }}>
      {/** Breadcrumbs */}
      {selectedCategoryChip && (
        <Grid container>
          <CBreadcrumbs
            sx={{ mt: 1, ml: 3 }}
            categoryData={subCategories}
            loading={isFetchingSubCategories}
            initialParents={[{ name: 'All Products', slug: '', type: '' }]}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
        </Grid>
      )}
      {/** Category Chips */}
      {subCategories ? (
        <StoreCategoryChildrenList
          sx={{ mb: '2.5rem' }}
          category={subCategories}
          loading={isFetchingSubCategories}
          onClickHandler={onChipClickHandler}
          storePageBreadcrumbs={storePageBreadcrumbs}
        />
      ) : (
        <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          <BrandCategoriesList
            sx={{ [theme.breakpoints.up('md')]: { mb: 2.5 } }}
            categories={brandCategory}
            onClickHandler={onChipClickHandler}
          />
        </Grid>
      )}
    </Grid>
  );
};

NavigationByCategories.propTypes = {
  brandSlug: PropTypes.string.isRequired,
  categorySlug: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
  sx: PropTypes.shape({}),
  shippingCountryCode: PropTypes.string,
  brandCategory: PropTypes.arrayOf(PropTypes.shape({})),
  storePageBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  selectedCategoryChip: PropTypes.string,
  setSelectedCategoryChip: PropTypes.func.isRequired,
};

export default NavigationByCategories;
