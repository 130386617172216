import React from 'react';
import PropTypes from 'prop-types';

import { Box, styled } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Link from 'components/Link/Link';
import CustomImage from 'components/CustomImage';

import useResponsive from 'hooks/useResponsive';

import { BLUR_DATA_URI } from 'constants/images-defaults';

const ImageWrapper = styled(Box)(() => ({
  width: 96,
  height: 96,
  position: 'relative',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fbf8f6',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    justifyContent: 'space-evenly',
  },
};

const StyledCardContent = styled(CardContent)(() => ({
  padding: '1rem 0rem 1rem',
}));

const advertisingCardDefaultPropSx = {};

const AdvertisingCard = ({
  title,
  body,
  link,
  image = '',
  backgroundColor,
  buttonLabel,
  sx = advertisingCardDefaultPropSx,
}) => {
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const responsiveStyles = isMobile
    ? {
        width: '100%',
        padding: 1.5,
      }
    : {
        width: 658,
        padding: 3.75,
        paddingBottom: 0.8,
      };

  return (
    <Card height="100%" sx={{ ...styles.root, ...responsiveStyles, backgroundColor, ...sx }}>
      <StyledCardContent>
        <Typography fontWeight="bold" mt={-0.75} textAlign gutterBottom component="h3" variant="h5" color="textPrimary">
          {title}
        </Typography>
      </StyledCardContent>
      {image && (
        <CardMedia>
          <ImageWrapper>
            <CustomImage src={image} layout="fill" objectFit="cover" placeholder="blur" blurDataURL={BLUR_DATA_URI} />
          </ImageWrapper>
        </CardMedia>
      )}
      <StyledCardContent color="textSecondary">{body}</StyledCardContent>
      <StyledCardContent sx={{ paddingBottom: 0 }}>
        <Link passHref type="inline" href={link} {...(isDesktop ? { target: '_blank' } : {})}>
          {buttonLabel}
        </Link>
      </StyledCardContent>
    </Card>
  );
};

AdvertisingCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
  link: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

export default AdvertisingCard;
