import gwcCampaign from './Advertising/campaigns/GWC';
import marketplaceCampaign1 from './Advertising/campaigns/marketplace1';
import marketplaceCampaign2 from './Advertising/campaigns/marketplace2';

export const ADVERTISING_REPEAT_INDEX = 14;

export const CURRENT_CAMPAIGN = gwcCampaign;

export const ADVERTISING_CAMPAIGNS = [
  {
    type: 'first',
    position: 4,
    campaign: marketplaceCampaign1,
  },
  {
    type: 'repeat',
    position: 14,
    campaign: gwcCampaign,
  },
  {
    type: 'default',
    position: 10,
    campaign: marketplaceCampaign2,
  },
];
