import { Typography } from '@mui/material';

export default {
  title: 'We guarantee lowest prices with our price match promise',
  body: (
    <>
      <Typography sx={{ paddingBottom: 1.75, textIndent: 0 }}>
        &quot;Kind and friendly support from the Creoate team! The platform is easy to use and navigate and I am very
        happy with how smooth this has been to set up! Thank you.&quot;
      </Typography>
      <Typography>Journaling,</Typography>
      <Typography>BowlerBearDesi</Typography>
    </>
  ),
  link: 'https://wholesale.creoate.com/price-match-promise',
  backgroundColor: '#F8F0E9',
  buttonLabel: 'Learn More',
};
