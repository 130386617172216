import { Typography } from '@mui/material';

export default {
  title: 'A very nice platform to shop for your business.',
  image: '/assets/images/ctas/GWCSection.png', // Replace with your actual image URL
  body: (
    <>
      <Typography sx={{ paddingBottom: 1.75, textIndent: 0 }}>
        &quot;I am very satisfied with this platform. A professional team, attentive and responsive. I was recommended
        by a friend who uses this platform. I am very satisfied with it as a professional. All services meet my needs
        perfectly. Thank you to the whole CREOATE team.&quot;
      </Typography>
      <Typography>Hawa</Typography>
    </>
  ),
  link: '/returns-policy',
  backgroundColor: '#FCF8F6',
  buttonLabel: 'Easy Returns',
};
