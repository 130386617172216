import bffAPI from 'services/api/bff';
import { getFilterURLParamsString } from 'utils/filter';
import { createEndpointWithParams } from 'utils/url-query';

export async function getProductsByCategory(category, params) {
  const paramsString = getFilterURLParamsString({ category, ...params }, true);
  return bffAPI.get(`/products?${paramsString}`).then(({ data }) => data);
}

export async function getProductsByBrand(brandSlug, limit = 16, offset = 0, shippingCountryCode = '') {
  const query = new URLSearchParams({
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(shippingCountryCode && { shippingCountryCode }),
  });

  const params = query.toString() ? `&${query.toString()}` : '';

  return bffAPI.get(`/products?brand=${brandSlug}${params}`).then(({ data }) => data);
}

export async function getProductsByStore(queryParams) {
  const endpoint = createEndpointWithParams('/products', queryParams);
  return bffAPI.get(endpoint).then(({ data }) => data);
}

export async function getProducts(params) {
  const paramsString = getFilterURLParamsString(params, true);
  return bffAPI.get(`/products?${paramsString}`).then(({ data }) => data);
}
