import PropTypes from 'prop-types';

/* Layout */
import { FullWidthLayout } from 'layouts';

/** Utils / Consts */
import routes from 'constants/routes';
import { logger } from 'services/api/logger';
import { createEndpointWithParams } from 'utils/url-query';
import { filterUrlQueryParams } from 'constants/category-filters';
import { getSSRInitialData, getSSRListsEndpoint, storeFiltersFormatter } from 'utils/SSR';
import { generateAlgoliaAnalyticsTags } from 'utils/functions';
import { getUserFromCookie } from 'utils/cookies';
import { DEFAULT_PRODUCTS_LIMIT, DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';

/** Provider */
import LegacyBrandPageLayout from 'components/Brand/LegacyBrandPageLayout';
import FEATURE_FLAGS from 'constants/feature-flags';
import BrandPageLayout from 'components/Brand/BrandPageLayout';

const brandBySlugDefaultPropBrandData = {
  id: '',
  attributes: {
    wholesalerName: '',
    wholesalerDescription: '',
  },
  store: {
    wholesalerStoreRating: '',
    wholesalerStoreNumberOfRatings: '',
    wholesalerStoreImages: {
      wholesalerStoreBanner: {
        wholesalerStoreBannerImage: '',
        wholesalerStoreBannerBasePath: '',
      },
    },
  },
};

const brandBySlugDefaultPropInitialProductsData = {
  products: [],
  totalProducts: 0,
};

const brandBySlugDefaultPropCollectionsList = [];
const brandBySlugDefaultPropInitialQueryParams = {};
const brandBySlugDefaultPropBrandCategory = [{}];
const brandBySlugDefaultPropInitialBreadcrumbs = [];

const brandBySlugDefaultPropInitialAlgoliaData = {
  filters: [],
  banners: [],
  products: [],
  totalProducts: 0,
  brands: [],
  totalBrands: 0,
  queryId: '',
};

const BrandBySlug = ({
  slug,
  isNewLayout,
  brandData = brandBySlugDefaultPropBrandData,
  initialProductsData = brandBySlugDefaultPropInitialProductsData,
  collectionsList = brandBySlugDefaultPropCollectionsList,
  initialQueryParams = brandBySlugDefaultPropInitialQueryParams,
  brandCategory = brandBySlugDefaultPropBrandCategory,
  initialBreadcrumbs = brandBySlugDefaultPropInitialBreadcrumbs,
  initialAlgoliaData = brandBySlugDefaultPropInitialAlgoliaData,
}) => {
  if (!isNewLayout) {
    return (
      <LegacyBrandPageLayout
        slug={slug}
        brandData={brandData}
        initialProductsData={initialProductsData}
        collectionsList={collectionsList}
        initialQueryParams={initialQueryParams}
        brandCategory={brandCategory}
        initialBreadcrumbs={initialBreadcrumbs}
      />
    );
  }
  return (
    <BrandPageLayout
      slug={slug}
      brandData={brandData}
      initialProductsData={initialProductsData}
      initialAlgoliaData={initialAlgoliaData}
    />
  );
};

BrandBySlug.propTypes = {
  slug: PropTypes.string.isRequired,
  isNewLayout: PropTypes.bool.isRequired,
  brandData: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerDescription: PropTypes.string,
    }),
    store: PropTypes.shape({
      wholesalerStoreRating: PropTypes.string,
      wholesalerShipsInDays: PropTypes.string,
      wholesalerStoreCountry: PropTypes.string,
      wholesalerStoreNumberOfRatings: PropTypes.string,
      wholesalerStoreImages: PropTypes.shape({
        wholesalerStoreBanner: PropTypes.shape({
          wholesalerStoreBannerImage: PropTypes.string,
          wholesalerStoreBannerBasePath: PropTypes.string,
        }),
      }),
    }),
  }),
  initialProductsData: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
  }),
  collectionsList: PropTypes.arrayOf(PropTypes.shape({})),
  initialQueryParams: PropTypes.shape({}),
  brandCategory: PropTypes.arrayOf(PropTypes.shape({})),
  initialBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
  initialAlgoliaData: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    banners: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
    brands: PropTypes.arrayOf(PropTypes.shape({})),
    totalBrands: PropTypes.number,
    queryId: PropTypes.string,
  }),
};

BrandBySlug.getLayout = FullWidthLayout;

export default BrandBySlug;

export const getServerSideProps = async ({ query, req }) => {
  const breadcrumbs = [{ name: 'Creoate', slug: '/', type: 'homepage' }];
  const { fetcher, userCountry, queryParams } = await getSSRInitialData({ query, req }, storeFiltersFormatter);

  const collectionsEndpoint = `/collections?wholesaler=${query.slug}`;

  const brandsCategoryEndpoint = createEndpointWithParams(`/categories?brand=${query.slug}&subcategories=true`, {
    shippingCountryCode: userCountry,
  });

  const brandsEndpoint = createEndpointWithParams(`/brands/${query.slug}`, { shippingCountryCode: userCountry });
  const productsEndpoint = createEndpointWithParams('/products', {
    brand: query.slug,
    shippingCountryCode: userCountry,
    [filterUrlQueryParams.SORT_BY]: DEFAULT_SORT_BY_VALUE,
    limit: query.limit ? query.limit : DEFAULT_PRODUCTS_LIMIT,
    skipVariations: true,
    ...queryParams,
  });

  const isNewLayout = req?.cookies?.feature_flags
    ? JSON.parse(req.cookies.feature_flags)?.find((x) => x?.name === FEATURE_FLAGS.ALGOLIA_BDP)?.value || true
    : true;

  const user = getUserFromCookie(req);

  const filterParamsExist = Object.keys(queryParams).some(
    (key) => key !== 'slug' // Assuming 'slug' is always part of the URL
  );
  const currentQueryParams = filterParamsExist ? queryParams : {};
  const { storePageSearchWithAlgoliaProductsEndpoint } = getSSRListsEndpoint({
    userCountry,
    queryParams: isNewLayout ? query : currentQueryParams,
  });

  const allPromises = [];

  try {
    if (!isNewLayout) {
      allPromises.push(fetcher.get(brandsEndpoint));
      allPromises.push(fetcher.get(productsEndpoint));
      allPromises.push(fetcher.get(collectionsEndpoint));
      allPromises.push(fetcher.get(brandsCategoryEndpoint));
      const [brandResponse, productResponse, collectionsList, brandCategoryResponse] = await Promise.all(allPromises);

      breadcrumbs.push({ name: brandResponse?.data?.attributes?.wholesalerName, slug: query.slug, type: 'brand' });

      return {
        props: {
          slug: query.slug,
          isNewLayout,
          collectionsList: collectionsList?.data?.collections || [],
          brandData: brandResponse?.data,
          initialProductsData: productResponse?.data,
          initialQueryParams: queryParams || {},
          brandCategory: brandCategoryResponse?.data,
          initialBreadcrumbs: breadcrumbs,
        },
      };
    }

    const analyticsTags = generateAlgoliaAnalyticsTags('store', user).join(',');

    const storePageSearchWithAlgoliaProductsEndpointWithTags = `${storePageSearchWithAlgoliaProductsEndpoint}&analyticsTags=${encodeURIComponent(
      analyticsTags
    )}`;

    allPromises.push(fetcher.get(brandsEndpoint));
    allPromises.push(fetcher.get(productsEndpoint));
    allPromises.push(fetcher.get(brandsCategoryEndpoint));
    allPromises.push(fetcher.get(storePageSearchWithAlgoliaProductsEndpointWithTags));

    const [brandResponse, productResponse, brandCategoryResponse, algoliaProductsResponse] = await Promise.all(
      allPromises
    );

    breadcrumbs.push({ name: brandResponse?.data?.attributes?.wholesalerName, slug: query.slug, type: 'brand' });

    return {
      props: {
        slug: query.slug,
        isNewLayout,
        brandData: brandResponse?.data,
        initialProductsData: productResponse?.data,
        brandCategory: brandCategoryResponse?.data,
        initialBreadcrumbs: breadcrumbs,
        initialAlgoliaData: {
          filters: algoliaProductsResponse?.data?.filters || [],
          banners: algoliaProductsResponse?.data?.banners || [],
          products: algoliaProductsResponse?.data?.products || [],
          totalProducts: algoliaProductsResponse?.data?.total || 0,
          brands: [],
          totalBrands: 0,
          queryId: algoliaProductsResponse?.data?.queryID || '',
        },
      },
    };
  } catch (err) {
    logger.error({ instance: 'product', type: 'getServerSideProps' }, err);

    // this is a "cant ship to your location" error
    if (err.response?.data?.errorCode === 'BRAND006') {
      return {
        redirect: {
          permanent: false,
          destination: routes.page.shippingRestricted,
        },
      };
    }

    return {
      redirect: {
        permanent: false,
        destination: routes.page.root,
      },
    };
  }
};
