import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

/** Components */
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import useResponsive from 'hooks/useResponsive';
import Typography from '@mui/material/Typography';

const storeCategoryChildrenListDefaultPropSx = {};

const StoreCategoryChildrenList = ({
  category,
  loading = false,
  sx = storeCategoryChildrenListDefaultPropSx,
  onClickHandler,
}) => {
  const isTablet = useResponsive('down', 'md');

  const [children, setChildren] = useState([]);

  /** Side Effects */
  useEffect(() => {
    // breadcrumbs
    if (children.length > 0) setChildren([]);
    if (category.children && Array.isArray(category.children)) {
      setChildren(category.children);
    }
  }, [children.length, category]);

  const componentStyles = isTablet
    ? {
        grid: {},
        loadingNameWidth: 200,
        textAlign: 'center',
        loadingChipArraySize: 3,
      }
    : {
        grid: { mb: 1 },
        loadingNameWidth: 300,
        textAlign: 'initial',
        loadingChipArraySize: 10,
      };

  const chipContainerStyle = !isTablet
    ? {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : {
        display: 'flex',
        overflow: 'auto',
        pb: 0.25,
        mt: 1,
      };

  return (
    <Grid container sx={{ ...sx }}>
      <Grid
        xs={12}
        item
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        {...componentStyles.grid}
      >
        {loading ? (
          <Skeleton variant="text" width={componentStyles.loadingNameWidth} height={40} />
        ) : (
          <Typography component="h1" variant="h4" textAlign={componentStyles.textAlign} marginTop={1}>
            {`Wholesale ${category.name}`}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sx={chipContainerStyle}>
        {loading
          ? [...Array(componentStyles.loadingChipArraySize).keys()].map((i) => (
              <Skeleton
                key={`category-children-chip-placeholder-${i}`}
                sx={{ mx: 0.5, borderRadius: 5 }}
                width={90}
                height={50}
              />
            ))
          : children.map((child) => (
              <Chip
                variant="outlined"
                label={child.name}
                title={child.name}
                sx={{
                  mx: 0.5,
                  my: 0.25,
                  '@media(max-width: 600px)': {
                    ml: 0,
                    mr: 0.5,
                  },
                }}
                key={`chip-subcategory-${child.slug}`}
                onClick={() => onClickHandler(child.name, child.slug)}
              />
            ))}
      </Grid>
    </Grid>
  );
};

StoreCategoryChildrenList.propTypes = {
  sx: PropTypes.shape({}),
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
};

export default StoreCategoryChildrenList;
