import PropTypes from 'prop-types';

/** Components */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const brandCategoriesListDefaultPropSx = {};

const BrandCategoriesList = ({ categories, onClickHandler, sx = brandCategoriesListDefaultPropSx }) => {
  return (
    <Box sx={{ ...sx, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {categories.map((category) => (
        <Chip
          component="h2"
          variant="outlined"
          label={category.name}
          sx={{ mx: 0.5, my: 0.25 }}
          key={`brand-categories-list-chip-subcategory-${category.slug}`}
          onClick={() => onClickHandler(category.name, category.slug)}
        />
      ))}
    </Box>
  );
};

BrandCategoriesList.propTypes = {
  sx: PropTypes.shape({}),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default BrandCategoriesList;
