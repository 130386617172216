import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

const storePageSeoDefaultPropProducts = [];

const StorePageSeo = ({
  title = 'CREOATE - Wholesale for Independents',
  url = 'www.creoate.com',
  bannerImage = 'https://i0.wp.com/www.creoate.com/wp-content/uploads/2019/02/cropped-final-logo-black-2.png',
  description = 'Award-winning, natural, and vegan skincare, handmade in England. Joanna Silva, the founder of Elan Skincare, benefits from years of professional experience in the medical industry – in wound care, infection prevention, and specifically in skincare',
  products = storePageSeoDefaultPropProducts,
  address = '',
}) => {
  const seoTittle = `${title} | Shop Wholesale on CREOATE`;
  const canonical = url.split(/[?&%]/)[0];
  const seoDescription = `Looking to stock items from ${title} in your store? Shop wholesale without the hassle on CREOATE. Fast delivery & 60-day returns.`;

  let baseUrl;
  if (typeof window !== 'undefined') {
    const { host } = window.location;
    baseUrl = `https://${host}`;
  } else {
    baseUrl = url;
  }
  const itemListElement = products.map((product, idx) => ({
    '@type': 'ListItem',
    position: idx + 1,
    url: `${baseUrl}/products/${product.productSlug}`,
    name: product.productName,
  }));

  const listItemJson = {
    '@context': 'http://schema.org',
    '@type': 'itemList',
    itemListElement,
  };

  const breadcrumbJsonLd = {
    context: 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: 'https://www.creoate.com/ ',
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: title,
        item: url,
      },
    ],
  };

  const SpeakableSchemaJsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    name: title,
    speakable: {
      '@type': 'SpeakableSpecification',
      xPath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url,
  };
  return (
    <>
      <Head>
        <title>{seoTittle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <meta property="og:title" content={seoTittle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={bannerImage} />

        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="CREOATE" />
        <meta property="og:image:width" content="472" />
        <meta property="og:image:height" content="110" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={seoTittle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:site" content="@creoateuk" />
        <meta name="twitter:creator" content="@creoateuk" />

        <link rel="canonical" href={canonical} />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Head>

      <script
        id="seo-script-store-SpeakableSpecification"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(SpeakableSchemaJsonLd),
        }}
      />

      <script
        id="seo-script-Store"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "http://schema.org/",
          "@id": "#Store",
          "@type": "Store",
          "name": "${title}",
          "address": "${address}",
          "description": "${description}",
          "image": "${bannerImage}",
          "url": "${url}"
        }`,
        }}
      />
      <script
        id="seo-script-ItemList"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(listItemJson),
        }}
      />

      <script
        id="seo-script_Breadcrumb"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(breadcrumbJsonLd),
        }}
      />
    </>
  );
};

StorePageSeo.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  bannerImage: PropTypes.string,
  description: PropTypes.string,
  products: PropTypes.instanceOf(Array),
  address: PropTypes.string,
};

export default StorePageSeo;
