import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useUpdateEffect } from 'react-use';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

/** Components */
import Box from '@mui/material/Box';
import LoadMore from 'components/LoadMore';
import LegacyProductCard from 'components/Product/LegacyProductCard';
import NoProductsMatchMessage from 'components/Listing/Messages/NoProductsMatch';

/** Providers */
import AddToCartPopoverProvider from 'provider/AddToCartPopoverProvider';

/** Utils / Const */
import { log, noOp } from 'utils/functions';
import { DEFAULT_PRODUCTS_LIMIT } from 'constants/listing-defaults';

/** Hooks */
import useFilters from 'hooks/useFilters';
import { useScrollToRef } from 'hooks/useScrollToRef';

/** API */
import { getProductsByStore } from 'api/product';

/** Blocks */
import ListingPage from 'blocks/ListingPage';
import AdvertisingSelector from 'components/Listing/Cards/Advertising/AdvertisingSelector';

const brandProductContentDefaultPropSx = {};

const brandProductContentDefaultPropInitialProductsData = {
  totalProducts: 0,
  products: {},
};

const BrandProductContent = ({
  sx = brandProductContentDefaultPropSx,
  slug = '',
  limit = DEFAULT_PRODUCTS_LIMIT,
  itemId = '',
  selectedCategory = '',
  handleLimitChange = noOp,
  handleItemIdChange = noOp,
  initialProductsData = brandProductContentDefaultPropInitialProductsData,
  shippingCountryCode,
}) => {
  const { layoutRef } = useFilters();
  const [, setShouldScrollTo] = useScrollToRef();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [total, setTotal] = useState(initialProductsData?.totalProducts);
  const [products, setProducts] = useState(initialProductsData?.products || []);
  const [offset, setOffset] = useState(initialProductsData?.products.length || DEFAULT_PRODUCTS_LIMIT);
  const [firstRender, setFirstRender] = useState(true);

  const { setShowLoadingState, showLoadingState } = useFilters();

  // When changing the initial products and brands value, set loading state to false
  useEffect(() => {
    setShowLoadingState(false);
  }, [initialProductsData, setShowLoadingState]);

  useEffect(() => {
    setProducts(initialProductsData?.products || []);
    setTotal(initialProductsData?.totalProducts || 0);
  }, [slug, initialProductsData]);

  const resetAll = () => {
    setTotal(0);
    setOffset(0);
    setProducts([]);
  };

  // load more
  const loadProducts = useCallback(
    async ({ offsetBy }) => {
      if (selectedCategory === slug) {
        setProducts(initialProductsData.products);
      } else {
        const queryParams = {
          limit,
          brand: slug,
          offset: offsetBy,
          shippingCountryCode,
          category: selectedCategory,
        };
        try {
          setIsLoadingMore(true);
          const data = await getProductsByStore(queryParams);
          if (data?.products.length) {
            setProducts((current) => [...current, ...data.products]);
            if (data.totalProducts) {
              setTotal(data.totalProducts);
            }
          }
        } catch (error) {
          log.error(`couldnt fetch more products. category-slug: ${slug}, params:${JSON.stringify(queryParams)}`);
        } finally {
          setIsLoadingMore(false);
        }
      }
    },
    [selectedCategory, slug, shippingCountryCode]
  );

  const handleLoadMore = async () => {
    handleLimitChange();
    await loadProducts({ offsetBy: offset });
  };

  useEffect(() => {
    setOffset(products.length);
  }, [products.length]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (!isEmpty(products) && !firstRender) {
      setShouldScrollTo(true);
    }
  }, [products, setShouldScrollTo]);

  useEffect(() => {
    setProducts(products);
  }, [products, setProducts]);

  useEffect(() => {
    if (showLoadingState) {
      resetAll();
    }
  }, [showLoadingState, slug]);

  useUpdateEffect(() => {
    resetAll();
  }, [selectedCategory]);

  useUpdateEffect(() => {
    (async () => {
      await loadProducts({ offsetBy: 0 });
    })();
  }, [selectedCategory]);

  return (
    <ListingPage ref={layoutRef} sx={{ ...sx }}>
      <ListingPage.ItemContainer>
        <AddToCartPopoverProvider>
          {!showLoadingState &&
            !isLoadingMore &&
            (products.length > 0 ? (
              products.map((product, index) => (
                <Fragment key={`product-list-item-${product.id}`}>
                  <ListingPage.Item
                    id={product.id}
                    data-scroll-to-element={product.id === itemId ? 'scroll-reference-id' : null}
                    justifyContent="space-between"
                    key={`product-id-${product.id}`}
                  >
                    <LegacyProductCard
                      handleItemIdChange={handleItemIdChange}
                      loading={false}
                      product={product}
                      index={index}
                    />
                  </ListingPage.Item>
                  <AdvertisingSelector index={index} listLength={products.length} />
                </Fragment>
              ))
            ) : (
              <NoProductsMatchMessage />
            ))}
        </AddToCartPopoverProvider>

        {(isLoadingMore || showLoadingState) &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
            <ListingPage.Item key={`loading-product-slug-${id}`}>
              <LegacyProductCard loading product={null} />
            </ListingPage.Item>
          ))}
      </ListingPage.ItemContainer>
      <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
        <LoadMore
          total={total}
          viewed={products.length}
          resource="products"
          isLoading={isLoadingMore}
          onClickHandler={handleLoadMore}
          progress={(products.length / total) * 100}
        />
      </Box>
    </ListingPage>
  );
};

BrandProductContent.propTypes = {
  sx: PropTypes.shape({}),
  limit: PropTypes.number,
  handleLimitChange: PropTypes.func,
  handleItemIdChange: PropTypes.func,
  initialProductsData: PropTypes.shape({
    totalProducts: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedCategory: PropTypes.string,
  slug: PropTypes.string,
  shippingCountryCode: PropTypes.string.isRequired,
  itemId: PropTypes.string,
};

export default BrandProductContent;
