import { Typography } from '@mui/material';

export default {
  title: 'Shop risk free with free 60 day returns',
  body: (
    <>
      <Typography sx={{ paddingBottom: 1.75, textIndent: 0 }}>
        &quot;Creoate offer a fantastic range of suppliers and products. Their credit options are great and the return
        within 60 days is really helpful. Definitely one of the best wholesale marketplaces out there!&quot;
      </Typography>
      <Typography>Elliot Papworth</Typography>
    </>
  ),
  link: '/returns-policy',
  backgroundColor: '#E9F0F9',
  buttonLabel: 'Learn More',
};
