import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { ADVERTISING_CAMPAIGNS, ADVERTISING_REPEAT_INDEX } from 'components/Listing/Cards/constants';
import ListingPage from 'blocks/ListingPage';
import AdvertisingCard from './AdvertisingCard';

const shouldShowChecks = {
  first: (index, position, listLength) => index + 1 === position || (listLength < position && index + 1 === listLength),
  repeat: (index, position) => (index + 1) % position === 0,
  default: (index, position) => index + 1 === position,
  repeatInOrder: (index, position, listLength) =>
    (index + 1) % position === 0 || (listLength < position && index + 1 === listLength),
};

const AdvertisingSelector = ({ index, listLength }) => {
  const advertising = ADVERTISING_CAMPAIGNS[Math.floor(index / 14) % 3];

  return (
    shouldShowChecks.repeatInOrder(index, ADVERTISING_REPEAT_INDEX, listLength) && (
      <ListingPage.AdvertiseWrapper
        key={`advertise-${index}-${(index + 1) % ADVERTISING_REPEAT_INDEX}-${advertising.id}`}
        sx={{ alignSelf: 'stretch' }}
      >
        <AdvertisingCard {...advertising.campaign} />
      </ListingPage.AdvertiseWrapper>
    )
  );
};

AdvertisingSelector.propTypes = {
  index: PropTypes.number.isRequired,
  listLength: PropTypes.number.isRequired,
};

export default AdvertisingSelector;
