import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import useAuth from 'hooks/useAuth';
import useFollowing from 'hooks/useFollowing';
import { sendBrandMessage } from 'api/user';
import { serverLogger } from 'utils/serverLogs.utils';

const MESSAGE_REASONS = ['Stock availability', 'Pricing', 'Shipping', 'Product details', 'Other'];

const MessageForm = ({ brandData, userId, onSubmit }) => {
  const [brandMessage, setBrandMessage] = useState({
    message_reason: '',
    message_content: '',
    message_to_id: brandData?.wpId || '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setBrandMessage((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = useCallback(() => {
    const newErrors = {
      message_reason: !brandMessage.message_reason,
      message_content: !brandMessage.message_content.trim(),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  }, [brandMessage]);

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);
    setErrors((prev) => ({ ...prev, apiError: '' }));

    try {
      const response = await sendBrandMessage({ userId, brandMessage });
      if (!response) throw new Error('Failed to send message');
      onSubmit();
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        apiError: 'An error occurred while sending your message. Please try again.',
      }));
      serverLogger.error('Error sending brand message', { error }, 'sendBrandMessage', 'client');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ mb: 1, fontSize: { xs: '1.125rem', sm: '1.25rem' } }}
        data-testid="message-brand-dialog-title"
      >
        Send a message to {brandData?.name}
      </Typography>
      <FormControl fullWidth>
        <Typography
          variant="body2"
          sx={{ mb: 0.5, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
          data-testid="message-brand-dialog-message-reason-select-title"
        >
          What&apos;s the reason for contacting this brand?&nbsp;<span>*</span>
        </Typography>
        <Select
          name="message_reason"
          value={brandMessage.message_reason}
          onChange={handleChange}
          displayEmpty
          error={errors.message_reason}
          renderValue={(value) => {
            if (!value) {
              return 'Select a reason';
            }
            return value;
          }}
          sx={{
            mb: 1,
            maxWidth: '13.375rem',
            '& .MuiSelect-select': { p: { xs: '0.75rem', sm: '1rem' }, fontSize: { xs: '0.75rem', sm: '1rem' } },
          }}
          data-testid="message-brand-dialog-message-reason-select"
        >
          {MESSAGE_REASONS.map((reason, index) => (
            <MenuItem
              key={reason}
              value={reason}
              sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, minHeight: { xs: '32px', sm: 'auto' } }}
              data-testid={`message-brand-dialog-message-reason-select-option-${index}`}
            >
              {reason}
            </MenuItem>
          ))}
        </Select>
        <Typography
          variant="body2"
          sx={{ mb: 0.5, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
          data-testid="message-brand-dialog-message-input-title"
        >
          Message&nbsp;<span>*</span>
        </Typography>
        <TextField
          name="message_content"
          multiline
          rows={4}
          value={brandMessage.message_content}
          onChange={handleChange}
          error={errors.message_content}
          sx={{ mb: 1, '.MuiInputBase-input': { fontSize: { xs: '0.75rem', sm: '1rem' } } }}
          slotProps={{ htmlInput: { maxLength: 5000, 'data-testid': 'message-brand-dialog-message-input' } }}
        />
      </FormControl>
      <Button
        variant="contained"
        fullWidth
        sx={{ fontSize: { xs: '0.8125rem', sm: '0.9375rem' }, py: { xs: '4px', sm: '8px' } }}
        onClick={handleSubmit}
        loading={loading}
        loadingPosition="end"
        data-testid="message-brand-dialog-send-message-button"
      >
        SEND MESSAGE
      </Button>
      {errors.apiError && (
        <Typography
          color="error"
          sx={{ mt: 0.5, fontSize: { xs: '0.65rem', sm: '0.875rem' } }}
          data-testid="message-brand-dialog-error"
        >
          {errors.apiError}
        </Typography>
      )}
    </>
  );
};

const MessageConfirmation = ({ email, isFollowing, followLoading, handleFollowButtonClick }) => (
  <>
    <Typography
      variant="h6"
      fontWeight="bold"
      sx={{ mb: 1, fontSize: { xs: '1.125rem', sm: '1.25rem' } }}
      data-testid="message-brand-dialog-confirmation-title"
    >
      Thank you for your message!
      <br />
      We&apos;ll email you [{email}] when you receive a response.
    </Typography>
    <Typography
      variant="body1"
      sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
      data-testid="message-brand-dialog-confirmation-message"
    >
      To view all your messages, go to &apos;My account &gt; My Inbox&apos;. If this isn&apos;t your email, please
      contact live chat.
    </Typography>
    <Button
      key={isFollowing ? 'following' : 'not-following'} // To prevent the rendering issue
      variant={isFollowing ? 'outlined' : 'contained'}
      fullWidth
      sx={{ mt: 1, fontSize: { xs: '0.8125rem', sm: '0.9375rem' }, py: { xs: '4px', sm: '8px' } }}
      onClick={handleFollowButtonClick}
      loading={followLoading}
      loadingPosition="end"
      data-testid="message-brand-dialog-confirmation-follow-brand-button"
    >
      {isFollowing ? 'FOLLOWING' : 'FOLLOW THE BRAND'}
    </Button>
    <Typography
      sx={{ fontSize: '0.75rem', mt: 0.5 }}
      data-testid="message-brand-dialog-confirmation-follow-brand-description"
    >
      Follow to get notified when this store runs a sale or adds new products.
    </Typography>
  </>
);

const MessageBrandDialog = ({ brandData, messageDialogOpen, handleMessageDialogClose }) => {
  const { following, follow, unfollow } = useFollowing();
  const { user } = useAuth();

  const email = user?.email || '';
  const brandId = brandData?.wpId;
  const userId = user?.retailerId;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);

  const isFollowing = following?.includes(brandId);

  const handleFollowButtonClick = useCallback(async () => {
    if (!brandId) return;

    setFollowLoading(true);
    try {
      await (isFollowing ? unfollow(brandId) : follow(brandId));
    } finally {
      setFollowLoading(false);
    }
  }, [isFollowing, brandId, follow, unfollow]);

  useEffect(() => {
    if (messageDialogOpen) setFormSubmitted(false);
  }, [messageDialogOpen]);

  return (
    <Dialog
      open={messageDialogOpen}
      onClose={handleMessageDialogClose}
      slotProps={{
        paper: {
          sx: { py: 2, px: 1.5, borderRadius: 2 },
        },
      }}
      fullWidth
      data-testid="message-brand-dialog"
    >
      <IconButton
        aria-label="close"
        onClick={handleMessageDialogClose}
        sx={{ position: 'absolute', right: { xs: 4, sm: 8 }, top: { xs: 4, sm: 8 } }}
        data-testid="message-brand-dialog-close-button"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 0 }}>
        <ChatOutlinedIcon sx={{ color: '#497262' }} />
        {formSubmitted ? (
          <MessageConfirmation
            email={email}
            isFollowing={isFollowing}
            followLoading={followLoading}
            handleFollowButtonClick={handleFollowButtonClick}
          />
        ) : (
          <MessageForm brandData={brandData} userId={userId} onSubmit={() => setFormSubmitted(true)} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MessageBrandDialog;
