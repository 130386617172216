import PropTypes from 'prop-types';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { noOp } from 'utils/functions';
import { decode } from 'html-entities';

const cBreadcrumbsDefaultPropSx = {};
const cBreadcrumbsDefaultPropInitialBreadcrumbs = [];

const CBreadcrumbs = ({
  sx = cBreadcrumbsDefaultPropSx,
  onBreadcrumbClick = noOp,
  initialBreadcrumbs = cBreadcrumbsDefaultPropInitialBreadcrumbs,
}) => {
  const handleOnClick = (breadcrumb) => {
    onBreadcrumbClick(breadcrumb);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} maxItems={5} sx={{ ...sx }}>
      {initialBreadcrumbs.map((breadcrumb) => (
        <Link
          aria-label={breadcrumb.name}
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleOnClick(breadcrumb)}
          key={`breadcrumb-${breadcrumb.name}`}
          data-testid={`breadcrumbs-link-${breadcrumb.slug}`}
          className={['brand', 'homepage'].includes(breadcrumb.type) ? 'notranslate' : ''}
        >
          {decode(breadcrumb.name)}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

CBreadcrumbs.propTypes = {
  sx: PropTypes.shape({}),
  onBreadcrumbClick: PropTypes.func,
  initialBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.oneOf(['category', 'collection', 'homepage', 'page', 'product', 'brand']),
    })
  ),
};

export default CBreadcrumbs;
