import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';

// mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

/** Components */
import StorePageSeo from 'components/Seo/StorePageSeo';
import BrandInformation from 'components/Brand/BrandInformation';
import VirgoFilters, { VIRGO_FILTERS } from 'components/VirgoFilters';
import BrandProductContent from 'components/Brand/BrandproductContent';
import NavigationByCategories from 'components/Brand/NavigationByCategories';

/* Layout */
import { FullWidthLayout } from 'layouts';

/** Utils / Consts */
import { GTMVisitedBrandPage } from 'utils/gtm';
import { filterUrlQueryParams } from 'constants/category-filters';
import { DEFAULT_PRODUCTS_LIMIT } from 'constants/listing-defaults';
import { setPriceInDesiredCurrency } from 'utils/functions';

/** Hooks */
import useAuth from 'hooks/useAuth';
import useFilters from 'hooks/useFilters';
import { useTheme } from '@mui/material/styles';
import useResponsive from 'hooks/useResponsive';
import { useQueryParamsState } from 'hooks/useQueryParamsState';
import useCurrency from 'hooks/useCurrency';

/** Provider */
import FiltersProvider from 'provider/FiltersProvider';
import CBreadcrumbs from 'components/CBreadcrumbs/CBreadcrumbs';
import SearchProductsBar from 'components/Header/SearchProductsBar';
import { LOCAL_STORAGE_KEYS, useLocalStorageQueue } from 'hooks/useLocalStorageQueue';

const legacyBrandPageLayoutDefaultPropBrandData = {
  id: '',
  attributes: {
    wholesalerName: '',
    wholesalerDescription: '',
  },
  store: {
    wholesalerStoreRating: '',
    wholesalerStoreNumberOfRatings: '',
    wholesalerStoreImages: {
      wholesalerStoreBanner: {
        wholesalerStoreBannerImage: '',
        wholesalerStoreBannerBasePath: '',
      },
    },
  },
};

const legacyBrandPageLayoutDefaultPropInitialProductsData = {
  products: [],
  totalProducts: 0,
};

const legacyBrandPageLayoutDefaultPropCollectionsList = [];
const legacyBrandPageLayoutDefaultPropInitialQueryParams = {};
const legacyBrandPageLayoutDefaultPropBrandCategory = [{}];
const legacyBrandPageLayoutDefaultPropInitialBreadcrumbs = [];

const LegacyBrandPageLayout = ({
  slug,
  brandData = legacyBrandPageLayoutDefaultPropBrandData,
  initialProductsData = legacyBrandPageLayoutDefaultPropInitialProductsData,
  collectionsList = legacyBrandPageLayoutDefaultPropCollectionsList,
  initialQueryParams = legacyBrandPageLayoutDefaultPropInitialQueryParams,
  brandCategory = legacyBrandPageLayoutDefaultPropBrandCategory,
  initialBreadcrumbs = legacyBrandPageLayoutDefaultPropInitialBreadcrumbs,
}) => {
  const theme = useTheme();
  const { layoutRef } = useFilters();
  const { userId, user } = useAuth();
  const { currency, exchangeRates } = useCurrency();
  const isTablet = useResponsive('down', 'md');
  const isDesktop = useResponsive('up', 'lg');
  const router = useRouter();
  const { asPath, pathname, query } = router;
  const [selectedCategory, setSelectedCategory] = useState('');
  const { addToQueue } = useLocalStorageQueue(LOCAL_STORAGE_KEYS.BRANDS);
  const [storePageBreadcrumbs, setStorePageBreadcrumbs] = useState(initialBreadcrumbs);
  const [itemId, setItemId] = useQueryParamsState(filterUrlQueryParams.ITEM_ID);
  const [selectedCategoryChip, setSelectedCategoryChip] = useState('');
  const [selectedBrandMin, setSelectedBrandMin] = useState(null);

  const [limit, setLimit] = useState(Number(query.limit) || DEFAULT_PRODUCTS_LIMIT);

  const handleLimitChange = () => {
    setLimit((prevState) => {
      router.push(
        {
          ...router,
          query: {
            ...router.query,
            limit: Number(prevState) + DEFAULT_PRODUCTS_LIMIT,
          },
        },
        undefined,
        { shallow: true }
      );

      return Number(prevState) + DEFAULT_PRODUCTS_LIMIT;
    });
  };

  useEffect(() => {
    if (initialBreadcrumbs !== storePageBreadcrumbs) {
      setStorePageBreadcrumbs(initialBreadcrumbs);
    }
  }, [initialBreadcrumbs]);

  const handleItemIdChange = (newItemId, resetItemId = false) => {
    if (!newItemId && !resetItemId) {
      return;
    }

    setItemId(newItemId);
  };

  const queryParametersHandlers = {
    handleLimitChange,
    handleItemIdChange,
    itemId,
  };

  const handleChipClick = (categoryName, categorySlug) => {
    setSelectedCategory(categorySlug);
    const slugExist = storePageBreadcrumbs.some((item) => item.slug === categorySlug);
    if (!slugExist) {
      setStorePageBreadcrumbs((old) => [...old, { name: categoryName, slug: categorySlug }]);
    }
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    const index = storePageBreadcrumbs.findIndex((item) => item.slug === breadcrumb.slug);
    if (index !== -1) {
      const clickedBreadcrumb = storePageBreadcrumbs[index];

      if (clickedBreadcrumb.slug === '/') {
        router.push(`/`);
      } else {
        const newBreadcrumbs = storePageBreadcrumbs.slice(0, index + 1);
        setStorePageBreadcrumbs(newBreadcrumbs);
        setSelectedCategoryChip(clickedBreadcrumb.slug);
        setSelectedCategory(clickedBreadcrumb.slug);
      }
    }
  };

  const shippingCountryCode = user?.shippingCountry;

  const hasCollections = collectionsList.length > 0;

  // Effect to set the minimum order amount based on the selected currency and store data
  useEffect(() => {
    const { store } = brandData || {};
    const { wholesalerStoreMinOrder } = store || {};

    if (currency?.name) {
      const minOrderInSelectedCurrency = wholesalerStoreMinOrder
        ?.filter(
          ({ wholesalerStoreMinOrderAmount, wholesalerStoreMinOrderCurrency }) =>
            wholesalerStoreMinOrderAmount > 0 && wholesalerStoreMinOrderCurrency === currency.name
        )
        .pop();

      if (minOrderInSelectedCurrency) {
        // If a minimum order is found in the selected currency, set it
        setSelectedBrandMin(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        // If no matching currency, calculate the price in the desired currency using exchange rates
        setPriceInDesiredCurrency(
          wholesalerStoreMinOrder,
          'wholesalerStoreMinOrderAmount',
          'wholesalerStoreMinOrderCurrency',
          currency.name,
          setSelectedBrandMin,
          exchangeRates
        );
      }
    }
  }, [currency, exchangeRates, brandData]);

  // Effect to trigger GTMVisitedBrandPage event when viewing the store page with valid brand data
  useEffect(() => {
    const { store, attributes, flairs, WP_id: wpId, slug: brandSlug } = brandData || {};
    const { wholesalerStoreCountry, wholesalerShipsInDays, wholesalerStoreLogo } = store || {};
    const { wholesalerStoreLogoBasePath, wholesalerStoreLogoImage } = wholesalerStoreLogo || {};

    if (brandData && !isEmpty(brandData) && selectedBrandMin !== null) {
      const flairCodes = flairs?.map(({ code }) => code);
      const brandCategoryNames = brandCategory?.map(({ slug: categorySlug }) => categorySlug);

      GTMVisitedBrandPage(
        attributes?.wholesalerName,
        wpId,
        wholesalerStoreCountry,
        wholesalerShipsInDays,
        `/store/${brandSlug}`,
        `${wholesalerStoreLogoBasePath}/${wholesalerStoreLogoImage}`,
        selectedBrandMin,
        currency.name,
        brandCategoryNames,
        attributes?.wholesalerDescription,
        flairCodes,
        userId
      );
      addToQueue({ id: brandData.id, name: brandData.attributes.wholesalerName });
    }
  }, [brandData, selectedBrandMin, userId, currency]);

  const filtersToBeApplied = [
    { id: VIRGO_FILTERS.FLAIRS },
    ...(hasCollections
      ? [
          {
            id: VIRGO_FILTERS.COLLECTIONS,
            componentProps: {
              selectOptions: collectionsList,
            },
          },
        ]
      : []),
    { id: VIRGO_FILTERS.ON_OFFER },
    { id: VIRGO_FILTERS.SORT_BY_STORE_PAGE, authProtected: true },
  ];

  return (
    <>
      <StorePageSeo
        title={brandData?.attributes?.wholesalerName}
        url={`https://www.creoate.com${asPath}`}
        bannerImage={`https://${brandData?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerBasePath}/${brandData?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerImage}`}
        canonical={`https://www.creoate.com${pathname}`}
        description={brandData?.attributes?.wholesalerDescription}
        products={initialProductsData?.products?.slice(0, 5)}
        address={brandData?.store?.wholesalerStoreCountry}
      />
      <FiltersProvider initialQueryParams={initialQueryParams}>
        {isTablet && <VirgoFilters isStorePage filters={filtersToBeApplied} defaultTab="brands" />}
        <Box sx={{ width: '100%' }}>
          <Box>
            <BrandInformation brand={brandData} sx={{ mb: 2 }} />
          </Box>

          <Container
            maxWidth={false}
            sx={{
              py: 1.25,
              [theme.breakpoints.down('md')]: {
                px: theme.spacing(1),
                pt: 1,
              },
            }}
          >
            <Grid container>
              <CBreadcrumbs
                sx={{ mt: 1, ml: 3 }}
                onBreadcrumbClick={(breadcrumb) => handleBreadcrumbClick(breadcrumb)}
                initialBreadcrumbs={storePageBreadcrumbs}
              />
            </Grid>
            <Grid container>
              <SearchProductsBar
                sx={{
                  mt: 1,
                  ml: 3,
                  width: '400px',
                  maxWidth: '20%',
                }}
              />
            </Grid>

            <NavigationByCategories
              brandSlug={slug}
              brandCategory={brandCategory}
              onClickHandler={handleChipClick}
              shippingCountryCode={shippingCountryCode}
              selectedCategoryChip={selectedCategoryChip}
              storePageBreadcrumbs={storePageBreadcrumbs}
              onBreadcrumbChange={handleBreadcrumbClick}
              setSelectedCategoryChip={setSelectedCategoryChip}
            />
            {isDesktop && <VirgoFilters isStorePage filters={filtersToBeApplied} defaultTab="brands" />}
            <BrandProductContent
              ref={layoutRef}
              sx={{
                mt: 1.875,
                [theme.breakpoints.up('md')]: {
                  mt: 2.5,
                },
              }}
              slug={slug}
              limit={limit}
              selectedCategory={selectedCategory}
              initialProductsData={initialProductsData}
              shippingCountryCode={shippingCountryCode}
              {...queryParametersHandlers}
            />
          </Container>
        </Box>
      </FiltersProvider>
    </>
  );
};

LegacyBrandPageLayout.propTypes = {
  slug: PropTypes.string.isRequired,
  brandData: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerDescription: PropTypes.string,
    }),
    store: PropTypes.shape({
      wholesalerStoreRating: PropTypes.string,
      wholesalerShipsInDays: PropTypes.string,
      wholesalerStoreCountry: PropTypes.string,
      wholesalerStoreNumberOfRatings: PropTypes.string,
      wholesalerStoreImages: PropTypes.shape({
        wholesalerStoreBanner: PropTypes.shape({
          wholesalerStoreBannerImage: PropTypes.string,
          wholesalerStoreBannerBasePath: PropTypes.string,
        }),
      }),
    }),
  }),
  initialProductsData: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({})),
    totalProducts: PropTypes.number,
  }),
  collectionsList: PropTypes.arrayOf(PropTypes.shape({})),
  initialQueryParams: PropTypes.shape({}),
  brandCategory: PropTypes.arrayOf(PropTypes.shape({})),
  initialBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

LegacyBrandPageLayout.getLayout = FullWidthLayout;

export default LegacyBrandPageLayout;
