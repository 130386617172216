import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

/** Components */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import VirgoFlair from 'components/Flair';
import Skeleton from '@mui/material/Skeleton';
import StarIcon from '@mui/icons-material/Star';
import CustomImage from 'components/CustomImage';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import MessageBrandDialog from 'components/Dialog/MessageBrandDialog/MessageBrandDialog';

/** Hooks */
import useAuth from 'hooks/useAuth';
import useCurrency from 'hooks/useCurrency';
import useFollowing from 'hooks/useFollowing';
import useResponsive from 'hooks/useResponsive';

/** Utils / Consts */
import { GTMFollowBrand } from 'utils/gtm';
import { RESOLUTION } from 'constants/image-resolution';
import { LIST_ITEM_FALLBACK } from 'constants/images-defaults';
import { transformImageUrl, setPriceInDesiredCurrency, truncate, addNoFollow } from 'utils/functions';

const BUTTON_TEXT_VALUES = Object.freeze({
  FOLLOW: 'Follow',
  FOLLOWING: 'Following',
  UNFOLLOW: 'Unfollow',
});

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'block',
    width: '100%',
  },
  container__Images: {
    position: 'relative',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '360px',
    avatar: {
      transform: 'translateY(-50px)',
      ml: '3rem',
      width: '95px',
      height: '95px',
      '@media screen and (max-width: 767px)': {
        m: 'auto',
      },
    },
  },
  container__info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    mt: -2,
    '@media screen and (max-width: 425px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    leftInformation: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      width: '25%',
      height: '100%',
      paddingTop: 0.5,
      mb: 1,
      '@media screen and (max-width: 425px)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
    rightInformation: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '100%',
      paddingTop: 0.5,
      paddingLeft: 1,
      width: '100%',
      '@media screen and (max-width: 425px)': {
        paddingRight: 1,
      },
      flairs: {
        mt: -3.5,
        pb: 1,
        '@media screen and (max-width: 425px)': {
          mt: -1,
        },
      },
    },
  },

  rating: {
    display: 'flex',
    alignItems: 'center',
    mb: '16px',
  },
  button: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    mt: 1,
    '@media screen and (max-width: 425px)': {
      mb: 1,
      maxWidth: '90%',
    },
  },
  brandInformation: {
    whiteSpace: 'pre-line',
  },
  brandInformationSkeleton: {
    '@media screen and (max-width: 425px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
};

const brandInformationDefaultPropSx = {};

const BrandInformation = ({ sx = brandInformationDefaultPropSx, brand, loading = false }) => {
  const { currency, exchangeRates } = useCurrency();
  const { userId, isAuthenticated } = useAuth();
  const isMobile = useResponsive('down', 'sm');
  const { following, follow, unfollow } = useFollowing();

  const [isFollowing, setIsFollowing] = useState(false);
  const [buttonText, setButtonText] = useState('Follow');
  const [buttonVariant, setButtonVariant] = useState('contained');
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState(currency?.symbol || '£');
  const [selectedMinOrderValue, setSelectedMinOrderValue] = useState(0);
  const [selectedMinReorderValue, setSelectedMinReorderValue] = useState(0);

  const { flairs } = brand;

  useEffect(() => {
    setIsFollowing(following?.includes(brand?.WP_id));
  }, [following, brand?.WP_id]);

  useEffect(() => {
    if (isFollowing) {
      setButtonText(BUTTON_TEXT_VALUES.FOLLOWING);
      setButtonVariant('outlined');
    } else {
      setButtonText(BUTTON_TEXT_VALUES.FOLLOW);
      setButtonVariant('contained');
    }
  }, [isFollowing]);

  const handleFollowButtonClick = () => {
    if (isFollowing) {
      unfollow(brand?.WP_id);
    } else {
      follow(brand.WP_id);
      GTMFollowBrand(
        brand.attributes.wholesalerName,
        brand.WP_id,
        brand.store.wholesalerStoreCountry,
        brand.store.wholesalerShipsInDays,
        userId
      );
    }
  };

  // react to currency change
  useEffect(() => {
    if (currency?.symbol) {
      setCurrencySymbol(currency.symbol);
    }
    if (currency?.name) {
      // min order
      const minOrderInSelectedCurrency = brand?.store?.wholesalerStoreMinOrder
        ?.filter((x) => x.wholesalerStoreMinOrderAmount > 0 && x.wholesalerStoreMinOrderCurrency === currency.name)
        .pop();

      if (minOrderInSelectedCurrency) {
        setSelectedMinOrderValue(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            brand?.store?.wholesalerStoreMinOrder,
            'wholesalerStoreMinOrderAmount',
            'wholesalerStoreMinOrderCurrency',
            currency.name,
            setSelectedMinOrderValue,
            exchangeRates
          );
        })();
      }

      // min reorder
      const minReorderInSelectedCurrency = brand?.store?.wholesalerStoreMinReorder
        ?.filter((x) => x.wholesalerStoreMinReorderAmount > 0 && x.wholesalerStoreMinReorderCurrency === currency.name)
        .pop();

      if (minReorderInSelectedCurrency) {
        setSelectedMinReorderValue(Number(minReorderInSelectedCurrency.wholesalerStoreMinReorderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            brand?.store?.wholesalerStoreMinReorder,
            'wholesalerStoreMinReorderAmount',
            'wholesalerStoreMinReorderCurrency',
            currency.name,
            setSelectedMinReorderValue,
            exchangeRates
          );
        })();
      }
    }
  }, [brand?.store?.wholesalerStoreMinOrder, brand?.store?.wholesalerStoreMinReorder, currency, exchangeRates]);

  const handleOnMouseEnter = () => {
    if (isFollowing) {
      setButtonText('Unfollow');
    }
  };

  const handleOnMouseLeave = () => {
    if (isFollowing) {
      setButtonText('Following');
    } else {
      setButtonText('Follow');
    }
  };

  let shipsInDays = '';

  const wholesalerAverageLeadTimeInteger = Number(brand.attributes.wholesalerRoundedLeadTime);

  if (wholesalerAverageLeadTimeInteger) {
    shipsInDays = `Ships in ${
      wholesalerAverageLeadTimeInteger === 1
        ? `${wholesalerAverageLeadTimeInteger} day`
        : `${wholesalerAverageLeadTimeInteger} days`
    }`;
  } else if (brand.store.wholesalerStoreLeadTime?.text) {
    shipsInDays = brand.store.wholesalerStoreLeadTime.text;
  }

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      <Box sx={styles.container}>
        {loading ? (
          <>
            <Skeleton variant="rectangular" animation="wave" sx={styles.container__Images} />
            <Skeleton variant="circular" animation="wave" sx={styles.container__Images.avatar} />
          </>
        ) : (
          <>
            <Box sx={styles.container__Images}>
              <CustomImage
                data-testid="brand-information-images-banner"
                src={transformImageUrl(
                  brand?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerBasePath,
                  brand?.store?.wholesalerStoreImages?.wholesalerStoreBanner?.wholesalerStoreBannerImage,
                  isMobile ? RESOLUTION.BANNER.MOBILE : RESOLUTION.BANNER.DESKTOP
                )}
                fallbackSrc={LIST_ITEM_FALLBACK}
                layout="fill"
                objectFit="cover"
                alt={brand?.attributes?.wholesalerName}
              />
            </Box>

            <Avatar
              data-testid="brand-information-images-avatar"
              sx={styles.container__Images.avatar}
              aria-label={brand?.attributes?.wholesalerName}
              src={transformImageUrl(
                brand?.store?.wholesalerStoreLogo?.wholesalerStoreLogoBasePath,
                brand?.store?.wholesalerStoreLogo?.wholesalerStoreLogoImage,
                isMobile ? RESOLUTION.LOGO.MOBILE : RESOLUTION.LOGO.DESKTOP,
                true
              )}
              alt={`${brand?.attributes?.wholesalerName} avatar`}
            />
          </>
        )}
        <Grid container sx={styles.container__info}>
          <Grid item xs={12} sm={5} md={4} lg={3} xl={2} sx={styles.container__info.leftInformation}>
            {loading ? (
              <Skeleton variant="text" animation="wave" width="100%" height="32px" />
            ) : (
              <Typography
                component="h1"
                variant="h6"
                color="textPrimary"
                data-testid="brand-information-container-leftInformation-brand-name"
                className="notranslate"
              >
                {brand?.attributes?.wholesalerName}
              </Typography>
            )}
            <Grid item xs={12} sx={styles.rating}>
              {loading ? (
                <>
                  <Skeleton variant="text" animation="wave" width="120px" height="24px" sx={{ mr: 0.5 }} />
                  <Skeleton variant="text" animation="wave" width="130px" height="24px" />
                </>
              ) : (
                brand?.store?.wholesalerStoreNumberOfRatings > 0 && (
                  <>
                    <Rating
                      sx={{ mr: 0.5 }}
                      data-testid="brand-information-container-leftInformation-rating"
                      aria-label="rating"
                      name="rating"
                      value={Number(brand?.store?.wholesalerStoreRating)}
                      readOnly
                      precision={0.5}
                      size="medium"
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    <Typography
                      xs={12}
                      variant="caption"
                      color="textPrimary"
                      data-testid="brand-information-container-leftInformation-text-rating"
                    >
                      {`(${Number(brand?.store?.wholesalerStoreRating).toFixed(1)} stars of ${
                        brand?.store?.wholesalerStoreNumberOfRatings
                      } ratings)`}
                    </Typography>
                  </>
                )
              )}
            </Grid>
            {loading ? (
              <Skeleton variant="text" animation="wave" width="60%" height="20px" />
            ) : (
              <Typography
                sx={styles.country}
                color="rgba(0, 0, 0, 0.6)"
                variant="body2"
                data-testid="brand-information-container-leftInformation-country"
              >
                {brand?.attributes?.wholesalerCountry}
              </Typography>
            )}
            {loading ? (
              <Skeleton variant="text" animation="wave" width="70%" height="20px" />
            ) : (
              <Typography
                sx={styles.orderValues}
                color="rgba(0, 0, 0, 0.6)"
                variant="body2"
                data-testid="brand-information-container-leftInformation-minimum"
              >
                {`Minimum ${currencySymbol}${selectedMinOrderValue} / Reorder ${currencySymbol}${truncate(
                  selectedMinReorderValue
                )}`}
              </Typography>
            )}
            {loading ? (
              <Skeleton variant="text" animation="wave" width="60%" height="20px" />
            ) : (
              <Stack direction="row" alignItems="center">
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  variant="body2"
                  data-testid="brand-information-container-leftInformation-shipIN"
                >
                  {shipsInDays}
                </Typography>
                <Tooltip placement="right" title="Estimated shipping time from when the seller receives your order.">
                  <InfoIcon fontSize="inherit" sx={{ color: 'grey.500', ml: 0.5 }} />
                </Tooltip>
              </Stack>
            )}
            {isAuthenticated &&
              (loading ? (
                <Skeleton variant="rectangular" animation="wave" height="40px" sx={styles.button} />
              ) : (
                <Stack direction="row" spacing={0.5} sx={{ my: 1 }}>
                  <Button
                    xs={12}
                    lg={4}
                    data-testid="brand-information-container-leftInformation-follow-button"
                    variant={buttonVariant}
                    color="primary"
                    sx={styles.button}
                    onClick={handleFollowButtonClick}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  >
                    {buttonText}
                  </Button>
                  <Button
                    data-testid="brand-information-container-leftInformation-message-button"
                    variant="outlined"
                    sx={styles.button}
                    onClick={() => {
                      setMessageDialogOpen(true);
                    }}
                  >
                    MESSAGE
                  </Button>
                  <MessageBrandDialog
                    brandData={{
                      name: brand?.attributes?.wholesalerName,
                      wpId: brand?.WP_id,
                    }}
                    messageDialogOpen={messageDialogOpen}
                    handleMessageDialogClose={() => {
                      setMessageDialogOpen(false);
                    }}
                  />
                </Stack>
              ))}
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={9} xl={10} sx={styles.container__info.rightInformation}>
            <Box sx={styles.container__info.rightInformation.flairs}>
              {flairs?.length
                ? flairs.map((flair) => (
                    <VirgoFlair
                      key={`virgo-flair-${flair.code}`}
                      sx={{
                        mb: 0.5,
                        zIndex: 1,
                        textTransform: 'uppercase',
                        marginRight: 1.5,
                      }}
                      flair={flair}
                    />
                  ))
                : null}
            </Box>
            {brand?.store?.wholesalerStoreDiscount[0]?.wholesalerStoreDiscountPercentage > 0 &&
              (loading ? (
                <Skeleton variant="text" animation="wave" width="100%" height="30px" sx={{ mb: 1 }} />
              ) : (
                <Typography
                  color="textPrimary"
                  variant="h6"
                  sx={{ mb: 1, textAlign: 'center', fontWeight: 'bold' }}
                  data-testid="brand-information-container-rightInformation-On_Offer"
                >
                  <span className="notranslate">{brand?.attributes?.wholesalerName}</span>{' '}
                  {`offering ${
                    brand.store.wholesalerStoreDiscount[0].wholesalerStoreDiscountPercentage / 100
                  }% off on orders of ${currencySymbol}${
                    brand.store.wholesalerStoreDiscount[0].wholesalerStoreDiscountMinOrderAmount / 100
                  } or
                  more`}
                </Typography>
              ))}

            {loading ? (
              <Box sx={styles.brandInformationSkeleton}>
                <Skeleton variant="text" animation="wave" width="90%" height="20px" />
                <Skeleton variant="text" animation="wave" width="80%" height="20px" />
                <Skeleton variant="text" animation="wave" width="60%" height="20px" />
                <Skeleton variant="text" animation="wave" width="80%" height="20px" />
                <Skeleton variant="text" animation="wave" width="90%" height="20px" />
              </Box>
            ) : (
              <Typography
                color="textPrimary"
                variant="body1"
                data-testid="brand-information-container-rightInformation-description"
                dangerouslySetInnerHTML={{ __html: addNoFollow(brand?.attributes?.wholesalerDescription) }}
                sx={styles.brandInformation}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

BrandInformation.propTypes = {
  sx: PropTypes.shape({}),
  brand: PropTypes.shape({
    id: PropTypes.string.isRequired,
    WP_id: PropTypes.string.isRequired,
    description: PropTypes.string,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerCountry: PropTypes.string,
      wholesalerDescription: PropTypes.string,
      wholesalerAverageLeadTime: '',
      wholesalerRoundedLeadTime: '',
    }).isRequired,
    flairs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        flairTitle: PropTypes.string,
        flairBackgroundColor: PropTypes.string,
        flairTextColor: PropTypes.string,
        data: PropTypes.shape({}),
      })
    ),
    store: PropTypes.shape({
      wholesalerStoreCountry: PropTypes.string,
      wholesalerStoreLeadTime: PropTypes.shape({
        text: PropTypes.string,
        minimumDays: PropTypes.number,
        maximumDays: PropTypes.number,
      }),
      wholesalerStoreImages: PropTypes.shape({
        wholesalerStoreSliderOne: PropTypes.shape({
          wholesalerStoreSliderOneImage: PropTypes.string,
          wholesalerStoreSliderOneBasePath: PropTypes.string,
        }),
        wholesalerStoreSliderTwo: PropTypes.shape({
          wholesalerStoreSliderTwoImage: PropTypes.string,
          wholesalerStoreSliderTwoBasePath: PropTypes.string,
        }),
        wholesalerStoreBanner: PropTypes.shape({
          wholesalerStoreBannerBasePath: PropTypes.string,
          wholesalerStoreBannerImage: PropTypes.string,
        }),
      }),
      wholesalerStoreLogo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      wholesalerStoreRating: PropTypes.string,
      wholesalerStoreNumberOfRatings: PropTypes.string,
      wholesalerShipsInDays: PropTypes.string,
      wholesalerStoreDiscount: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreDiscountPercentage: PropTypes.number,
          wholesalerStoreDiscountMinOrderAmount: PropTypes.number,
        })
      ),
      wholesalerStoreMinOrder: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
      wholesalerStoreMinReorder: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinReorderAmount: PropTypes.number,
          wholesalerStoreMinReorderCurrency: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

export default BrandInformation;
